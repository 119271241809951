import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import "./index.css"
import { getImageNodes } from "../lib"
import useMedia from "../lib/useMedia"
import PhotoGallery from "../components/photogallery/PhotoGallery"

function useSlideShow(photos) {
  const [showImageAt, setShowImageAt] = useState(0)
  useEffect(() => {
    const id = setTimeout(() => {
      setShowImageAt(value => {
        if (value < photos.length - 1) {
          return value + 1
        }
        return 0
      })
    }, 5000)

    return () => clearTimeout(id)
  })
  return showImageAt
}

export default function Home({ data }) {
  const IMAGES = getImageNodes(data)
  const isMobile = useMedia("(max-width: 650px)")

  if (isMobile) {
    return (
      <Layout>
        <PhotoGallery photos={IMAGES} />
      </Layout>
    )
  }

  return <DesktopHome images={IMAGES} />
}

function DesktopHome({ images }) {
  const showImageAt = useSlideShow(images)
  return (
    <Layout>
      <div style={{ position: "relative" }}>
        {images.map((image, idx) => (
          <img
            className="home-image"
            src={image.src}
            alt={image.name}
            key={image.src}
            style={{
              opacity: showImageAt === idx ? 1 : 0,
            }}
          />
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Home {
    allFile(filter: { relativeDirectory: { eq: "home" } }) {
      edges {
        node {
          name
          base
          publicURL
        }
      }
    }
  }
`
